html {
  z-index: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.2;
  background-color: black;
  background-color: var(--nightOcean);
  color: var(--paper);
  transition: 1s ease-in-out;
}

header {
  padding: 1.3rem;
  padding-top: 2rem;
  padding-left: 2rem;
  text-align: left;
}

h1 {
  font-size: 5.3svw;
  font-weight: 700;
  line-height: 1;
  font-style: italic;
  color: var(--paper);
}

h2 {
  font-size: 2.7svw;
  font-weight: 700;
  line-height: 1.3;
  padding-bottom: 2rem;
}

header h2 {
  margin-bottom: 1rem;
  font-size: 2.1svh;
  font-weight: 500;
  font-style: italic;
  color: white;
}

nav ul {
  margin-top: 2rem;
  display: flex;
  justify-content: left;
}

header h1,
nav ul li {
  display: inline;
}

nav ul li a {
  padding: 7px;
  font-size: 2.2svw;
}

strong {
  color: var(--tirtiary);
}

#about {
  padding-left: 0.7svw;
}

.aboutbox {
  display: flex;
  background-color: rgb(10, 20, 49);
  width: 100%;
  height: auto;
}

.iconUl {
  text-align: right;
  transition: 0.5s;
  float: right;
  z-index: 1;
  margin-right: 1%;
}

.iconUl:hover {
  cursor: pointer;
}

.toTop {
  position: fixed;
  right: 30px;
  bottom: 70px;
  z-index: 1;
}

.toTop-button {
  width: 65px;
  height: 55px;
  background-color: var(--deepOcean);
  border-radius: 10px;
  color: var(--paper);
  border: none;
  box-shadow: 0 10px 15px #0000001f;
  transition: 0.5s ease-in-out;
  z-index: 1;
}

.text {
  font-family: "Roboto Slab", sans-serif;
  color: white;
  text-align: center;
}

.HomeBox {
  display: flex;
  justify-content: left;
  align-items: left;
  background-color: rgb(243, 244, 245);
}

.oceanBox {
  height: 4svh;
}

#abouttext {
  text-align: left;
  line-height: 1.4;
  font-size: 18px;
  color: var(--paper);
  padding: 9svw;
  padding-top: 7svw;
  padding-bottom: 7rem;
  margin-bottom: 0.1rem;
}

#portraitBox {
  flex: 1 0 40%;
  margin: 4rem;
  padding-top: 3rem;
  padding-bottom: 5rem;
  min-width: 350px;
  min-height: 450px;
  max-width: 450px;
  max-height: 550px;
}

#educationBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  padding: 3rem;
  background-color: rgb(23, 24, 25);
  align-items: start;
}

#educationBox h2 {
  flex: 1 0 100%;
  font-size: 32px;
}

.award {
  flex: 1 0 100%;
  padding: 2rem;
  margin: 1rem;
  max-width: 400px;
  height: 75svh;
  background-color: var(--deepGreen);
}

.award h3 {
  font-size: 21px;
  padding-bottom: 0.2rem;
}

.award h4 {
  color: var(--tirtiary);
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
}

.award p {
  line-height: 1.4;
  margin-bottom: 1rem;
  font-size: 18px;
  padding-top: 1rem;
}

#loadBox {
  width: 100%;
  height: 80svh;
  background-color: var(--deepOcean);
  text-align: center;
}

#loadBox p {
  font-size: 18px;
  font-weight: 700;
  padding: 5rem;
}

.portfolioBox {
  width: 100%;
  height: auto;
}

.projBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  padding: 2em 4em 15em 4em;
  background-color: rgb(23, 24, 25);
}

.projBox:nth-child(even) {
  flex-direction: row;
}

.projBox:nth-child(even) {
  background-color: black;
}

.projBox:nth-child(3),
.projBox:nth-child(7) {
  background-color: var(--deepOcean);
}

#projImgBox {
  flex: 1 0 30%;
  margin: 1rem;
  padding-left: 0.1rem;
  padding-top: 7rem;
  /* min-height: 450px; */
  height: 500px;
  /* min-width: 450px; */
}

#projTextBox {
  flex: 1 0 40%;
  margin: 3rem;
  padding: 2rem;
}

#projTextBox p {
  font-size: 18px;
  line-height: 1.3;
  white-space: pre-wrap;
}

#techBox h3,
#linkBox h3 {
  margin-top: 1rem;
  font-size: 16px;
}

#techBox ul,
#linkBox ul {
  display: inline-flex;
  flex-wrap: wrap;
}

#techBox li {
  margin-right: 0.3rem;
  margin-top: 0.4rem;
  padding: 0.5rem;
  background-color: var(--primary);
  color: white;
  font-weight: 600;
}

#linkBox li {
  margin-right: 0.4rem;
  margin-top: 0.5rem;
  padding: 0.8rem;
  background-color: var(--secondary);
  color: white;
  border-radius: 7px;
  transition: 0.5s ease;
}

#linkBox li a {
  color: var(--paper);
  font-weight: 600;
}

/* ********CONTACT PAGE******** */
#contactBox {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 2rem;
  height: 100svh;
  background-color: rgb(23, 24, 25);
}

#contactTextBox {
  flex: 1 0 50%;
  text-align: left;
  margin: 2rem;
  padding: 5rem;
}

form {
  flex: 1 0 20%;
}

input,
textarea {
  background-color: var(--nightOcean);
  color: white;
  border: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--paper);
}

input:focus {
  border: none;
}

.form-row {
  flex-direction: row;
  flex: 1 0 100%;
  padding: 1rem;
  margin: 0.5rem;
  width: 30svw;
}

.error-text {
  padding-top: 0.7rem;
  padding-left: 0.7rem;
  text-align: left;
  color: var(--alert);
}

button {
  background-color: var(--primary);
  background-color: var(--secondary);
  border: none;
  color: var(--paper);
  font-size: 110%;
  font-weight: 700;
}

.contact-dark .error-text {
  color: var(--paper);
}

footer {
  min-height: 100px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  padding-top: 3em;
  padding-bottom: 0.1em;
  position: relative;
}

footer p {
  font-weight: 500;
}

/* *************:::::::::::::::************ PSEUDO *************::::::::::************************ */

.clicked,
.clicked li {
  background-color: rgb(0, 0, 0);
  color: white;
  transition: 0.5s ease-in;
}

nav ul a li:hover,
nav ul a li:focus {
  color: white;
}

.homeLinks a {
  transition: 0.5s ease;
}
.homeLinks a:hover,
.homeLinks a:focus,
.homeLinks a:active {
  color: var(--tirtiary);
}

#linkBox li:hover,
#linkBox li:focus,
#linkBox li:active {
  background-color: var(--secondHover);
}

.pics:hover,
.pics:focus,
.pics:active {
  box-shadow: 9px 30px 32px -15px rgba(0, 0, 0, 0.632);
}

figure img {
  opacity: 70%;
  transition: 0.5s ease;
}

figure img:hover,
figure img:focus,
figure img:active {
  opacity: 100%;
}

.toTop-button:hover {
  background-color: var(--primary);
}

.toTop-button:focus {
  background-color: var(--deepOcean);
}

#projImgBox:nth-child(1) {
  opacity: 0.65;
  transition: 0.5s ease-in-out;
}

#projImgBox:nth-child(1):hover {
  opacity: 0.95;
}

button {
  outline: black solid;
  transition: 0.7s ease-in-out;
}

button:hover,
button:focus,
button:active {
  outline: 1px solid black;
  background-color: var(--secondHover);
}

input[type="text"],
textarea[type="textarea"] {
  outline: black solid;
  transition: 0.7s ease-in-out;
}

input[type="text"]:focus,
textarea[type="textarea"]:focus,
input[type="text"]:active,
textarea[type="textarea"]:active {
  outline: 1px solid var(--secondHover);
}
